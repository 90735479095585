import { useLayoutEffect, useState } from 'react';
import _debounce from 'lodash.debounce';
import { useEnvironment } from 'yoshi-flow-editor-runtime';

export function useSizeListener() {
  const { isSSR } = useEnvironment();
  const [width, setWidth] = useState(isSSR ? 0 : window.innerWidth);

  useLayoutEffect(() => {
    const resizeWindow = _debounce(() => {
      setWidth(isSSR ? 0 : window.innerWidth);
    }, 100);
    !isSSR && window.addEventListener('resize', resizeWindow);
    return () => {
      if (!isSSR) {
        return window.removeEventListener('resize', resizeWindow);
      }
    };
  });

  return width;
}
